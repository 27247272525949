<template>
    <div>
        <div class="ditchbox">
            <div class="management">
                <div class="article_head" v-if="hasPerm(['article.category.store'])">
                    <el-button type="primary" @click="handleClassOper">新增分类</el-button>
                </div>
                <div class="article_table">
                    <el-table :data="tableData" style="width:100%" row-key="id"
                        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
                        <el-table-column label="分类名称" prop="name" min-width="300"></el-table-column>
                        <el-table-column label="排序" prop="sort" min-width="150"></el-table-column>
                        <el-table-column label="文章数" prop="articles_count" min-width="150">
                            <template slot-scope="scope">
                                <a href="javascript:void(0);">{{ scope.row.articles_count }}</a>
                            </template>
                        </el-table-column>
                        <el-table-column label="时间" prop="created_at" min-width="200"></el-table-column>
                        <el-table-column label="操作" min-width="200" align="center">
                            <template slot-scope="scope">
                                <div class="caozuo">
                                    <a href="javascript:void(0);" @click="handleModify(scope.row)"
                                        v-if="hasPerm(['article.category.update'])">编辑</a>
                                    <a href="javascript:void(0);" @click="handleDelete(scope.row)"
                                        v-if="hasPerm(['article.category.delete'])">删除</a>
                                </div>
                            </template>
                        </el-table-column>
                        <template slot="empty">
                            <div class="empty_data">
                                <p>暂无数据</p>
                            </div>
                        </template>
                    </el-table>
                </div>
            </div>
        </div>
        <el-dialog :title="selectArticle.name ? '编辑分类' : '新增分类'" :visible.sync="addClass" width="30%">
            <div class="compile_form">
                <el-form :model="classForm" ref="classForm" :rules="classRules" label-width="100px">
                    <el-form-item label="分类名称" prop="title">
                        <div class="">
                            <el-input v-model="classForm.title"></el-input>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div class="goodsbtn attr_foot_cancel" @click="addClass = false; selectArticle = ''">取 消</div>
                <div class="goodsbtn" @click="addDoNameSubmit"
                    v-if="hasPerm(['article.category.update', 'article.category.store'])">提 交</div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { Message } from 'element-ui';

export default {
    data() {
        return {
            tableData: [], //table data
            selectArticle: '', //选中的文章
            addClass: false,
            classForm: {
                title: '',
            },
            classRules: {
                title: [{
                    required: true,
                    message: '请输入分类名称',
                    trigger: 'blur',
                }]
            },
            pageInfo: {
                page: 1,
                limit: 10
            },
            searchObj: {
                search: [],
                order: [

                    {
                        key: 'id',
                        value: 'asc'
                    }
                ]
            }
        }
    },
    created() {
        this.getArticleClassList();
    },
    methods: {
        // 获取文章分类列表
        getArticleClassList() {
            this.$get(this.$apis.contentArticleClassApi, { ...this.searchObj, ...this.pageInfo }).then(res => {
                if (res.code != 200) {
                    return Message.error({ message: res.message, offset: 200 });
                }
                this.$set(this, 'tableData', res.data.list);
            })
        },
        // 添加分类
        handleClassOper() {
            this.$router.push("/channel/content/article_class_oper");
        },
        // 编辑分类
        handleModify({ id }) {
            this.$router.push({ path: "/channel/content/article_class_oper", query: { id } });
        },
        // 删除分类
        handleDelete(row) {
            this.$confirm(`确定要将分类【${row.name}】以及子类永久删除吗?`, '警告', {
                type: "warning"
            }).then(() => {
                console.log(1);
                this.$delete(`${this.$apis.contentArticleClassApi}/${row.id}`).then(res => {
                    Message[(() => res.code == 200 ? 'success' : 'error')()]({
                        message: `删除文章分类${res.code == 200 ? '成功!' : res.message}`,
                        offset: 200
                    })
                    // 刷新table
                    this.getArticleClassList();
                })
            }).catch((err) => {
                // Message[err != 'cancel' ? 'error' : 'info']({ message: err != 'cancel' ? err : "已取消删除!", offset: 200 });
            })
        },
        addDoNameSubmit() {
            let that = this;
            this.$refs['classForm'].validate((valid) => {
                if (valid) {
                    that.submitClass()
                } else {
                    that.$message({
                        message: '请输入分类名称',
                        type: 'warning'
                    })
                    return false;
                }
            });

        },
        submitClass() {
            this.addClass = false;
            this.selectArticle = '';
        },
    }
}
</script>
<style scoped>
@import url("css/ditch.css");

.caozuo {
    border-right: none;
}
</style>